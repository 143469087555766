import React from 'react';
// import heroImage from '../../assets/images/profile.png';
import '../../css/LandingPage/HeroSection.css';

function HeroSection() {
    return (
        <section id="home" className="firstsection bg-black text-light">
            <div className="container h-100 custom-padding">
                <div className="row h-100 align-items-center justify-content-center">
                    <div className="col-lg-8">
                        <div>
                            <h1 className="text-center text-lg-start">&lt;/&gt;</h1>
                            <h2 className="fw-bold text-center text-lg-start">Hi I'm Utkarsh Agarwal</h2>
                            <p className="lead mb-4 text-center text-lg-start">
                                A creative, AI enthusiast and Frontend Web Developer, skilled in
                                Python, Data Visualization, NLP, and Deep Learning. My focus is on
                                innovative problem-solving and impactful frontend design for
                                websites and applications, aiming to drive efficiency and innovation
                                in technology projects.
                            </p>
                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="https://www.linkedin.com/in/utkarshagarwal97" target="_blank" rel="noreferrer">
                                    <button type="button" className="btn btn-custom btn-lg px-4">Let's Connect on LinkedIn</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block">
                        <img src='/brand/profile.png' alt="Utkarsh Agarwal" className="img-fluid p-5" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroSection;
