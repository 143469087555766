import React from 'react';
import '../../css/LandingPage/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top-line"></div>
            <div className="footer-content">
                <p className="footer-text">© 2024 Utkarsh Agarwal. All rights reserved.<br />
                    No part of this site may be reproduced without our written permission.
                </p>
                <div className="footer-icons">
                <a href="www.linkedin.com/in/utkarshagarwal97" target="_blank" rel="noopener noreferrer" className="social-link">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://www.instagram.com/utkarsh.melbourne/" target="_blank" rel="noopener noreferrer" className="social-link">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61557237792512" target="_blank" rel="noopener noreferrer" className="social-link">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://twitter.com/utkagarwal97/" target="_blank" rel="noopener noreferrer" className="social-link">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    <a href="mailto:contact@utkagarwal.com" target="_blank" rel="noopener noreferrer" className="social-link">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
