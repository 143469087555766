import React from 'react';
import '../../css/LandingPage/Scroll.css';

// TODO: Take it from the image to inhance its look.


const Scroll = () => {
    return (
        <section className="scroll-section">
            <div className="container">
                <div className="row scroll-section-area">
                    <div className="col-4 scroll-text">
                        <h2>Worked At:</h2>
                    </div>
                    <div className="col-8 scroll-img">
                        <div className="scroll-area">
                            <div className="scroll imgBox" style={{ '--time': '50s' }}>
                                <div>
                                    {Array.from({ length: 16 }).map((_, index) => (
                                        <img key={index} src={`/scroll/image_${index + 1}.webp`} alt={`${index + 1}`} />
                                    ))}
                                </div>
                                <div>
                                    {Array.from({ length: 16 }).map((_, index) => (
                                        <img key={index + 16} src={`/scroll/image_${index + 1}.webp`} alt={`${index + 1}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Scroll;