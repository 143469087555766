import React, { useState } from 'react';
import '../../css/LandingPage/Skills.css';
import '../../index.css';

import python from "../../assets/skill-icons/technical/python.webp"
import html from "../../assets/skill-icons/technical/html.webp"
import css from "../../assets/skill-icons/technical/css.webp"
import java from "../../assets/skill-icons/technical/javascript.webp"
import swift from "../../assets/skill-icons/technical/swift.webp"
import php from "../../assets/skill-icons/technical/php.webp"
import react from "../../assets/skill-icons/technical/react.webp"
import tailwindcss from "../../assets/skill-icons/technical/tailwindcss.webp"
import bootstrap from "../../assets/skill-icons/technical/bootstrap.webp"
import flask from "../../assets/skill-icons/technical/flask.webp"
import xcode from "../../assets/skill-icons/technical/xcode.webp"
import notebook from "../../assets/skill-icons/technical/notebook.webp"
import tensorflow from "../../assets/skill-icons/technical/tensorflow.webp"
import huggingface from "../../assets/skill-icons/technical/huggingface.webp"
import github from "../../assets/skill-icons/technical/github.webp"
import bitbucket from "../../assets/skill-icons/technical/bitbucket.webp"
import openai from "../../assets/skill-icons/technical/openai.webp"
import vscode from "../../assets/skill-icons/technical/vscode.webp"
import colab from "../../assets/skill-icons/technical/colab.webp"


import excel from "../../assets/skill-icons/productivity/excel.webp"
import word from "../../assets/skill-icons/productivity/word.webp"
import ppt from "../../assets/skill-icons/productivity/ppt.webp"
import dynamic from "../../assets/skill-icons/productivity/dynamic.webp"
import doc from "../../assets/skill-icons/productivity/doc.webp"
import sheet from "../../assets/skill-icons/productivity/sheet.webp"
import slide from "../../assets/skill-icons/productivity/slide.webp"
import business from "../../assets/skill-icons/productivity/business.webp"
import deputy from "../../assets/skill-icons/productivity/deputy.webp"
import hubspot from "../../assets/skill-icons/productivity/hubspot.webp"
import slack from "../../assets/skill-icons/productivity/slack.webp"


import premiere from "../../assets/skill-icons/creativity/premiere.webp"
import photoshop from "../../assets/skill-icons/creativity/photoshop.webp"
import illustrator from "../../assets/skill-icons/creativity/illustrator.webp"
import imovie from "../../assets/skill-icons/creativity/imovie.webp"
import figma from "../../assets/skill-icons/creativity/figma.webp"
import sketch from "../../assets/skill-icons/creativity/sketch.webp"
import canva from "../../assets/skill-icons/creativity/canva.webp"
import admanager from "../../assets/skill-icons/creativity/admanager.webp"
import sendgrid from "../../assets/skill-icons/creativity/sendgrid.webp"
import wordpress from "../../assets/skill-icons/creativity/wordpress.webp"
import wix from "../../assets/skill-icons/creativity/wix.webp"

import leader from "../../assets/skill-icons/management/leader.webp"
import admin from "../../assets/skill-icons/management/admin.webp"
import operational from "../../assets/skill-icons/management/operational.webp"
import problem from "../../assets/skill-icons/management/problem.webp"
import learning from "../../assets/skill-icons/management/learning.webp"
import project from "../../assets/skill-icons/management/project.webp"



function SkillsSection() {
    const skillsData = {
        technical: [
            { name: "Python", level: "Advanced", years: "3-5 Years", icon: python},
            { name: "HTML", level: "Expert", years: "Over 10 Years", icon: html },
            { name: "CSS", level: "Expert", years: "Over 10 Years", icon: css},
            { name: "JavaScript", level: "Advanced", years: "5-10 Years", icon: java },
            { name: "Swift", level: "Intermediate", years: "Less Than 1 Year", icon: swift },
            { name: "php", level: "Intermediate", years: "1-3 years", icon: php },
            { name: "React", level: "Beginner", years: "Less Than 1 Year", icon: react },
            { name: "Tailwind CSS", level: "Intermediate", years: "1-3 years", icon: tailwindcss },
            { name: "Bootstrap", level: "Advanced", years: "Over 10 Years", icon: bootstrap },
            { name: "Flask", level: "Intermediate", years: "3-5 Years", icon: flask },
            { name: "Xcode", level: "Intermediate", years: "Less Than 1 Year", icon: 
            xcode },
            { name: "Jupyter Notebook", level: "Expert", years: "3-5 Years", icon: notebook },
            { name: "TensorFlow", level: "Advanced", years: "3-5 Years", icon: tensorflow },
            { name: "Hugging Face", level: "Intermediate", years: "1-3 years", icon: huggingface },
            { name: "Github", level: "Advanced", years: "3-5 Years", icon: github },
            { name: "Bitbucket", level: "Intermediate", years: "1-3 years", icon: bitbucket },
            { name: "OpenAI", level: "Intermediate", years: "1-3 years", icon: openai },
            { name: "VS Code", level: "Expert", years: "Over 10 Years", icon: vscode },
            { name: "Colab", level: "Advanced", years: "3-5 Years", icon: colab },
        ],
        productivity: [
            { name: "Excel", level: "Expert", years: "Over 10 Years", icon: excel },
            { name: "Word", level: "Expert", years: "Over 10 Years", icon: word },
            { name: "Powerpoint", level: "Expert", years: "Over 10 Years", icon: ppt },
            { name: "Dynamic 365", level: "Advanced", years: "Less Than 1 Year", icon: dynamic },
            { name: "Docs", level: "Expert", years: "Over 10 Years", icon: doc },
            { name: "Sheets", level: "Expert", years: "Over 10 Years", icon: sheet },
            { name: "Slides", level: "Expert", years: "Over 10 Years", icon: slide },
            { name: "Deputy", level: "Advanced", years: "3-5 years", icon: deputy },
            { name: "Hubspot", level: "Advanced", years: "3-5 years", icon: hubspot },
            { name: "Slack", level: "Advanced", years: "5-10 years", icon: slack },

            // TODO - add apple suite as well

        ],
        creativity: [
            { name: "Premiere", level: "Expert", years: "Over 10 Years", icon: premiere },
            { name: "Photoshop", level: "Advanced", years: "Over 10 Years", icon: photoshop },
            { name: "Illustrator", level: "Expert", years: "Over 10 Years", icon: illustrator },
            { name: "iMovie", level: "Expert", years: "Over 10 Years", icon: imovie },
            { name: "Figma", level: "Expert", years: "Over 10 Years", icon: figma },
            { name: "Sketch", level: "Expert", years: "Over 10 Years", icon: sketch },
            { name: "Canva", level: "Expert", years: "Over 10 Years", icon: canva },
            { name: "Meta Ad Manager", level: "Advanced", years: "Over 10 Years", icon: admanager },
            { name: "SendGrid", level: "Advanced", years: "3-5 years", icon: sendgrid },
            { name: "WordPress", level: "Advanced", years: "5-10 years", icon: wordpress },
            { name: "Wix", level: "Expert", years: "Over 10 Years", icon: wix },
            { name: "Google My Business", level: "Expert", years: "Over 10 Years", icon: business },

        ],
        management: [
            { name: "Team Leadership", level: "Advanced", years: "5-10 years", icon: leader },
            { name: "Administrative Planning", level: "Advanced", years: "5-10 years", icon: admin },
            { name: "Operational Efficiency", level: "Advanced", years: "5-10 years", icon: operational },
            { name: "Problem Solving", level: "Advanced", years: "5-10 years", icon: problem },
            { name: "Continuous Learning", level: "Advanced", years: "5-10 years", icon: learning },
            { name: "Project Management", level: "Advanced", years: "5-10 years", icon: project },
        ]
    };


    function SkillPill({ skill }) {
        const [isVisible, setIsVisible] = useState(false);
    
        return (
            <div className="skill-pill"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}>
                {skill.name}
                {isVisible && (
                    <div className="custom-tooltip">
                        <div className="tooltip-content">
                            <div className="tooltip-icon">
                                <img src={skill.icon} alt={skill.name} />
                            </div>
                            <div className="tooltip-text">
                                <div>Skill Proficiency: {skill.level}</div>
                                <div>Years of Practice: {skill.years}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
    

    return (
        <section id="about" className='first-section'>
            <div className="about-section mx-auto">
                <h2>About Me</h2>
                <p>Welcome to my corner of the web, where I delve into my journey through the tech world, spotlighting my programming prowess and technological know-how. My path is paved with continuous learning and diverse project experiences, ranging from crafting engaging web applications to exploring the vast universe of data science and machine learning.</p>
            </div>

            <div className="skills-section">
                <div className="container skills-container">
                    <div className="row gap-5 skills-rows">
                        <div className="col-md-5">
                            <h3>Technical & Software</h3>
                            {skillsData.technical.map((skill, index) => (
                                <SkillPill key={index} skill={skill} />
                            ))}
                        </div>
                        <div className="col-md-5">
                            <h3>Productivity & Tools</h3>
                            {skillsData.productivity.map((skill, index) => (
                                <SkillPill key={index} skill={skill} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="skills-section">
                <div className="container skills-container">
                    <div className="row gap-5 skills-rows">
                        <div className="col-md-5">
                            <h3>Creativity & Marketing</h3>
                            {skillsData.creativity.map((skill, index) => (
                                <SkillPill key={index} skill={skill} />
                            ))}
                        </div>
                        <div className="col-md-5">
                            <h3>Management & Organizational</h3>
                            {skillsData.management.map((skill, index) => (
                                <SkillPill key={index} skill={skill} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SkillsSection;
