import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashLink as Link } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import '../../css/LandingPage/NavBar.css';

const NavBar = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expanded={expanded} expand="lg" className="navbar-custom" onToggle={() => setExpanded(expanded ? false : "expanded")}>
            <Container fluid className="container-fluid-custom">
                <Navbar.Brand href="#home" className="navbar-brand-custom d-block d-lg-none">
                    <img src='brand/logo.png' alt="Logo" className="logo-img" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="navbar-toggle-custom" />
                <Navbar.Collapse id="responsive-navbar-nav" className="navbar-collapse-custom">
                    <Nav className="center-links">
                        <Link smooth to="#about" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>Skills</Link>
                        <Link smooth to="#work" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>Portfolios</Link>
                        <Link smooth to="https://www.cropshop97.com" target='_blank' className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>Crop Shop</Link>
                    </Nav>
                    <Nav className="ms-auto justify-content-end nav-lin">
                        <a href="www.linkedin.com/in/utkarshagarwal97" target="_blank" rel="noopener noreferrer" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://www.instagram.com/utkarsh.melbourne/" target="_blank" rel="noopener noreferrer" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61557237792512" target="_blank" rel="noopener noreferrer" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                        <a href="https://twitter.com/utkagarwal97/" target="_blank" rel="noopener noreferrer" className="nav-link nav-link-custom" onClick={() => setExpanded(false)}>
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
