import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { Carousel } from 'react-bootstrap';

import '../../css/LandingPage/WorkDisplay.css';
import '../../index.css';

const galleries = {
    technical: [
        { type: 'video', src: 'https://www.youtube.com/embed/8dOZj5NycNE', thumbnail: 'portfolio/technical/image1.png', title: 'Retail Management System', skills: "Python, VS Code, Retail Management, Database Management" },
        { type: 'video', src: 'https://www.youtube.com/embed/hGXGxSaKrak', thumbnail: 'portfolio/technical/image2.png', title: 'Sentiment Analysis using Twitter', skills: "Python, Jupyter Notebook, JSON, VS Code, Database Management" },
        { type: 'video', src: 'https://www.youtube.com/embed/1UBCFXma9o4', thumbnail: 'portfolio/technical/image3.png', title: 'Ethical AI to Counter the Dark Side of Generative AI', skills: "Python, VS Code, HTML, CSS, JavaScript, Flask, Bootstrap, NLP, OpenAI, HuggingFace, Sentiment, Tokenization, LLMs, BERT, RoBERTa" },
    ],
    webdevelopment: [
        { type: 'video', src: 'portfolio/webdevelopment/website1.gif', thumbnail: 'portfolio/webdevelopment/website1.gif', title: 'Blue Gem Conveyancing, Melbourne', text: "Blue Gem Conveyancing, based in Mount Waverley and serving the South Eastern Suburbs, simplifies the property conveyancing process for buying, selling, and transferring properties. They focus on providing comprehensive support, ensuring all necessary documentation and legal requirements are handled efficiently. The firm emphasizes hassle-free service with inclusive pricing that covers both professional services and disbursements, aiming to offer excellent customer service and satisfaction. Their website offers detailed information on their services, customer testimonials, and contact details. For more information, visit their website at <a href='https://bluegemconveyancing.com.au/' target='_blank'>Blue Gem Conveyancing</a>." },
        { type: 'video', src: 'portfolio/webdevelopment/website2.gif', thumbnail: 'portfolio/webdevelopment/website2.gif', title: 'Canberra India Council, Canberra', text: "The Canberra India Council (CIC) is a non-profit organization established in 2010 to foster cultural and trade relations between Canberra and India. It aims to promote mutual appreciation of cultural values and facilitate trade dialogues by partnering with government agencies, the diplomatic corps, and industry bodies in both nations. The CIC organizes various events and activities that emphasize the enrichment of these bilateral relations. For more details on their events and membership, visit their website at <a href='https://canberraindiacouncil.org/' target='_blank'>Canberra India Council</a>." },
        { type: 'video', src: 'portfolio/webdevelopment/website3.gif', thumbnail: 'portfolio/webdevelopment/website3.gif', title: 'Crop Shop - The Creative Agency, Melbourne', text: "Crop Shop - The Creative Agency is dedicated to enhancing your digital presence through tailored solutions that meet specific needs. The agency offers a range of services including website development, search engine optimization, video editing, and graphic design. Their focus is on simplifying the digital journey for their clients, maximizing value, and helping businesses stand out in the competitive digital landscape. For more detailed information, visit their website at <a href='www.cropshop97.com' target='_blank'>Crop Shop - The Creative Agency</a>." },
        { type: 'video', src: 'portfolio/webdevelopment/website4.gif', thumbnail: 'portfolio/webdevelopment/website4.gif', title: 'Agrasen Mansion Boys Hostel, India', text: "Agrasen Mansion Boys Hostel in Dehradun provides comfortable and convenient accommodation for students, particularly those attending UPES in Bidholi. The hostel offers double-sharing rooms equipped with essential amenities like personal study tables, cozy mattresses, and ample storage. Additional facilities include a gym, table tennis, and a multi-cuisine mess serving nutritious meals. The hostel is designed to offer a supportive environment for both studying and relaxation. For more information, visit their website at <a href='https://www.agrasenmansion.com/' target='_blank'>Agrasen Mansion Boys Hostel</a>." },
        { type: 'video', src: 'portfolio/webdevelopment/website5.gif', thumbnail: 'portfolio/webdevelopment/website5.gif', title: '3d Youth Films Group, India', text: "The 3d Youth Films Group website is dedicated to promoting positive societal changes through creative and active youth engagement. They focus on organizing various events, short films, and awareness programs to showcase the potential and creativity of the youth. The group offers numerous opportunities for individuals to contribute and grow within this vibrant community. For more details and to explore participation opportunities, visit their website at <a href='https://3dyfg.github.io/website' target='_blank'>3d Youth Films Group</a>." },
    ],
    creative: [
        { type: 'image', src: 'portfolio/creative/1/header-image.webp', title: 'Social Post Designing', carousel: 'portfolio/creative/1/image1.webp, portfolio/creative/1/image2.webp,portfolio/creative/1/image3.webp,portfolio/creative/1/image4.webp,portfolio/creative/1/image5.webp,portfolio/creative/1/image6.webp,portfolio/creative/1/image7.webp' },
        { type: 'image', src: 'portfolio/creative/2/header-image.webp', title: 'Social Post Designing', carousel: 'portfolio/creative/2/image1.webp, portfolio/creative/2/image2.webp,portfolio/creative/2/image3.webp,portfolio/creative/2/image4.webp' },
        { type: 'image', src: 'portfolio/creative/3/header-image.webp', title: 'Social Post Designing', carousel: 'portfolio/creative/3/image1.webp, portfolio/creative/3/image2.webp,portfolio/creative/3/image3.webp,portfolio/creative/3/image4.webp' },
    ],
    photography: [
        { type: 'image', src: 'portfolio/photography/image1.jpg', title: 'Leafy Canopy Focus', text: "The image features a shallow depth of field, with the foreground occupied by vibrant green leaves sharply in focus, showcasing their textures and the play of light on their surfaces. The background, featuring a blurred figure sitting and some architectural structures, is out of focus, which directs the viewer's attention to the leaves. The soft bokeh effect in the background adds a pleasing aesthetic quality to the photo.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image2.jpg', title: 'Fruit Medley Delight', text: "The image is a brightly colored still life composition of a fruit platter. Captured the variety of textures and the glossy surface of the fruits, making them look fresh and appetizing. The natural light appears to come from the side, casting soft shadows and giving depth to the image. The contrasting colors are vivid and balanced, with the reds, greens, and yellows standing out against the neutral background.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image3.jpg', title: 'Floral Elegance', text: "A nature shot, where the focus is on a cluster of red flowers, amidst green foliage. The shallow depth of field blurs the background, which helps in isolating the subject and drawing the viewer's eye to the flowers. The image captures the delicate structure of the blooms and leaves, and the lighting seems natural and diffused, enhancing the organic feel of the photo.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image4.jpg', title: 'Urban Echoes', text: "The image captures an urban scene, specifically a dumpster with various discarded items. The focus on the dumpster, with its rough textures and the graffiti, speaks to a documentary style, capturing details that are often overlooked. The image tells a story about waste, neglect, and perhaps the environment. The light is flat, which is fitting for the stark realism of the subject matter.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image5.jpg', title: 'Rainwalk Sentinel', text: "This image is of a lone dog walking on wet asphalt, witnessed a recent rain. The dog's coat has a tinge of orange-brown, which stands out against the grey background. The composition captures the movement and the direction of the dog's walk, with a focus that is sharp enough to detail the dog's fur and expression, yet soft enough to keep the background unobtrusive.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image6.jpg', title: 'Vibrant Coleus Tapestry', text: "The photograph captures the lush textures and striking color contrast of Coleus plants. The deep reds intermingled with vibrant greens create a lively pattern that is emphasized by the soft, natural lighting. The composition is close and personal, inviting the viewer to appreciate the intricate details of the plant's foliage.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image7.jpg', title: 'Succulent Spikes', text: "The image presents a close-up of an Aloe plant, focusing on its spiky, succulent leaves dusted with white speckles. The light is soft and diffused evenly, accentuating the plant's geometric patterns and the serrated edges of the leaves. The shallow depth of field throws the background into a gentle blur, highlighting the Aloe's texture.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image8.jpg', title: 'Thread of Creativity', text: "This is a creative, close-up shot of a thread spool, with the focus sharply on the opening of the spool, while the textured thread creates a soft, colorful background. The macro photography technique used here brings attention to the everyday object's texture and form, with the shallow depth of field enhancing its prominence.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image9.jpg', title: 'Nighttime Flames', text: "The image is a dynamic capture of a fire, with the flames leaping upward in a play of light and shadow. The low lighting conditions bring out the warm tones of the fire against the dark background, creating a sense of movement and energy. The isolated brightness and the contrasting darkness evoke the coziness and drama of a night by the fire.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image10.jpg', title: '"Microcosm of Nature', text: "In this image, we see a close-up of a green plant's stems, with a tiny spider at the center, signifying the circle of life in nature. The droplets and the fine web strands add to the texture, while the soft focus on the background puts the emphasis on the main subject, bringing out the details of the plant and the spider.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image11.jpg', title: 'Illuminated Isolation', text: "A low-key, dramatic image that plays with light and shadow, capturing the stark contrast between the illuminated paper and the surrounding darkness. The bokeh effect of the light spots in the background adds an element of mystery and abstraction, focusing the viewer's attention on the lit central object.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." },
        { type: 'image', src: 'portfolio/photography/image12.jpg', title: 'Relics of Time', text: "This black and white photograph showcases the textures and forms of an old, dilapidated structure, juxtaposed against a more modern building in the background. The monochrome tones highlight the contrast between the past and the present, emphasizing the architectural details and the sense of abandonment.<br> Check out more images at my <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Unsplash Profile</a>." }
    ],
};

const tabContent = {
    technical: {
        heading: "Technical Projects",
        description: "Discover a comprehensive showcase of my technical projects here, each meticulously crafted utilizing a diverse array of programming languages and frameworks. I warmly invite you to share your thoughts and guidance—your insights are invaluable as I strive for continuous improvement and mastery in my field."
    },
    webdevelopment: {
        heading: "Web Development",
        description: "Explore my curated collection of web development projects, where I've skillfully applied various web technologies and design principles to create immersive digital experiences. Your feedback and suggestions are greatly appreciated, as they fuel my growth and enhance the quality of my work in the ever-evolving landscape of web development."
    },
    creative: {
        heading: "Creative Works",
        description: "Dive into my marketing and design portfolio, a vibrant showcase of strategic campaigns and creative solutions that blend innovation with aesthetic appeal. I value your perspective and welcome any feedback or insights that could refine my approach and drive excellence in the realms of marketing and design."
    },
    photography: {
        heading: "Photography",
        description: "Welcome to my photography portfolio, a visual journey through moments captured with passion and precision. Each piece reflects my dedication to the art of photography and my exploration of light, composition, and emotion. I invite your comments and critiques, as they are essential in sharpening my vision and enriching my artistic journey. Watch more images on my Unsplash profile: <a href='https://unsplash.com/@utkarshagarwal' target='_blank'>Click Here</a>."
    }
};


const Gallery = ({ tabName, content }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isVideo, setIsVideo] = useState(false);

    const handleItemClick = (item) => {
        // Determine if 'text' is present, else parse skills string into an array
        const updatedItem = {
            ...item,
            skillsArray: item.skills ? item.skills.split(',').map(skill => skill.trim()) : [],
            text: item.text || "",
            carouselImages: item.carousel ? item.carousel.split(',').map(img => img.trim()) : [item.src]
        };
        setModalContent(updatedItem);
        setIsVideo(item.type === 'video');
        setShowModal(true);
    };

    const images = galleries[tabName] || [];
    return (
        <>
            <div className='portfolio-grid-headings'>
                <h3>{content.heading}</h3>
                <p dangerouslySetInnerHTML={{ __html: content.description }}></p>
            </div>
            {/* This is to make the creative grid in square */}
            {/* <div className={`portfolio-gallery-grid ${tabName === 'creative' ? 'creative-grid' : ''}`}>
                {images.map((item, index) => (
                    <div key={index} className={`portfolio-aspect-ratio-box ${tabName === 'creative' ? 'creative-box' : ''}`} onClick={() => handleItemClick(item)}>
                        <img src={item.type === 'image' ? item.src : item.thumbnail} alt={`Gallery ${tabName} ${index}`} className="portfolio-aspect-ratio-img" />
                    </div>
                ))}
            </div> */}

            <div className={`portfolio-gallery-grid`}>
                {images.map((item, index) => (
                    <div key={index} className={`portfolio-aspect-ratio-box`} onClick={() => handleItemClick(item)}>
                        <img src={item.type === 'image' ? item.src : item.thumbnail} alt={`Gallery ${tabName} ${index}`} className="portfolio-aspect-ratio-img" />
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} size={tabName === 'creative' || tabName === 'photography' ? "md" : "lg"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={isVideo ? "video-responsive-modal" : ""}>
                    {tabName === 'creative' ? (
                        modalContent.carouselImages && modalContent.carouselImages.length > 1 ? (
                            <Carousel interval={2500}>
                                {modalContent.carouselImages.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            className="d-block w-100"
                                            src={image}
                                            alt={`Creative Slide ${index}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        ) : (
                            <img src={modalContent.src} alt="Modal Content" style={{ width: '100%', height: 'auto' }} />
                        )
                    ) : (
                        isVideo ? (
                            <div className="video-responsive">
                                <iframe src={modalContent.src} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title={`Video ${modalContent.src}`}></iframe>
                            </div>
                        ) : (
                            <img src={modalContent.src} alt="Modal Content" style={{ width: '100%', height: 'auto' }} />
                        )
                    )}
                </Modal.Body>

                {modalContent.text ? (
                    <Modal.Footer>
                        <div>
                            <strong><p>{tabName === 'webdevelopment' ? "About the business:" : "Image story"}</p></strong>
                            <p dangerouslySetInnerHTML={{ __html: modalContent.text }}></p>
                        </div>
                    </Modal.Footer>
                ) : (
                    modalContent.skillsArray && modalContent.skillsArray.length > 0 && (
                        <Modal.Footer>
                            <div>
                                <strong><p>Skills Used:</p></strong>
                                {modalContent.skillsArray.map((skill, index) => (
                                    <p key={index} className="skill-pill">{skill}</p>
                                ))}
                            </div>
                        </Modal.Footer>
                    )
                )}
            </Modal>
        </>
    );
};


function Portfolio() {

    return (
        <>
            <div id='work'><br /></div>
            <section className='portfolio-gallery-section'>
                <div className='portfolio-work-section'>
                    <h2>Experience Portfolios</h2>
                </div>
                <div className="portfolio-worksection">
                    <Tabs
                        defaultActiveKey="technical"
                        id="portfolio-justify-tab-example"
                        justify
                    >
                        <Tab eventKey="technical" title="Technical">
                            <Gallery tabName="technical" content={tabContent['technical']} />
                        </Tab>
                        <Tab eventKey="webdevelopment" title="Web Development">
                            <Gallery tabName="webdevelopment" content={tabContent['webdevelopment']} />
                        </Tab>
                        <Tab eventKey="creative" title="Creative">
                            <Gallery tabName="creative" content={tabContent['creative']} />
                        </Tab>
                        <Tab eventKey="photography" title="Photography">
                            <Gallery tabName="photography" content={tabContent['photography']} />
                        </Tab>
                    </Tabs>
                </div>
            </section>
        </>
    );
}

export default Portfolio;
