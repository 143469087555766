import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import NavBar from './components/LandingPage/NavBar';
import HeroSection from './components/LandingPage/HeroSection';
import Skills from './components/LandingPage/Skills';
import WorkDisplay from './components/LandingPage/WorkDisplay';
import Footer from './components/LandingPage/Footer';
import Scroll from './components/LandingPage/Scroll';

function App() {
  return (
    <div className="App">
      <NavBar />
      <HeroSection />
      {/* <AboutSection />
      <SkillsSection /> */}
      <Skills />
      <Scroll />
      <WorkDisplay />
      <Footer />
    </div>
  );
}

export default App;
